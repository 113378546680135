.fixed {
  position: fixed;
  top: 56px;
  right: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

.notifications {
  display: flex;
  flex-direction: column;
  max-width: 783px;
  padding: 1rem;
  margin: auto;
}

.notifications > * {
  margin-bottom: 1rem;
  pointer-events: all;
}
