.sidebar {
  --btn-icon-hover-bg-color: transparent;
  --icon-fill-color: #fff;
  width: var(--bar-width);
  background-color: var(--body-color);
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 56px;
  z-index: 2;
}

.sidebar a:hover, .sidebar button:hover {
  background-color: #23232F;
}

.sidebar .itemIcon {
  color: #c4c4cd;
}

.sidebar a:hover > .itemIcon, .sidebar button:hover > .itemIcon {
  color: #ffffff;
}

.itemLabelContainer {
  display: none;
}

.sidebar a:hover > .itemLabelContainer, .sidebar button:hover > .itemLabelContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #23232F;
  left: 56px;
  height: 52px;
  width: max-content;
  padding: 8px 16px;
}

.itemLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #FFFFFF;
}


.sidebar > *:last-child {
  margin-top: auto;
}

.link {
  width: 56px;
  height: 52px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.sidebar :global(.active) {
  background: #23232F
}

.sidebar :global(.active) > .itemIcon {
  color: #ffffff;
}

.sidebar :global(.active)::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: var(--branding);
}
