.appBar {
  --bar-width: 56px;
  height: var(--bar-width);
  background-color: var(--header-bg-color);
  color: #fff;
  display: flex;
  padding-left: var(--bar-width);
  padding-right: 1rem;
  align-items: center;
}

.appBar > h3 {
  font-weight: normal;
  margin: 0;
  padding-top: 18px;
  flex: 1;
}

.userType {
  color: var(----body-bg) !important;
  padding-right: 43px;
}

.logo {
  width: 32px;
  margin-right: 1em;
}
