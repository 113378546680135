.container {
  position: fixed;
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e2e3c;
}

.card {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  width: 364px;
  height: 348px;
  gap: 1rem;
}
