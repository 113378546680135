$motif-font-path: '~@ey-xd/motif-react/assets/fonts';

@import '~@ey-xd/motif-react/styles.scss';

html {
  background-color: var(--metadata-bg-color);
}

body {
  background-color: var(--metadata-bg-color);
  font-family: var(--primary-font) !important;
  --btn-font-weight: normal;
}

.content {
  --bar-width: 56px;
  margin-left: var(--bar-width);
  position: relative;
  min-height: calc(100vh - 56px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.read-only {
  pointer-events: none;

  .motif-select-input,
  .motif-text-area,
  .motif-input,
  .motif-input:disabled,
  .motif-date-picker-wrapper .motif-date-picker {
    background: none;
    box-shadow: none;
    border-bottom: none;
    svg {
      display: none;
    }
  }
  .motif-radio-button {
    opacity: 0.5;
  }
}

.home-wrapper {
  margin: 1%;
  padding: 1%;
}

.signout-button {
  display: flex;
  flex-direction: row-reverse;
}

.table-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5%;
  max-width: 75%;
}

.table-element-wrapper {
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
  & td,
  th {
    border: 1px solid #cdcdcd;
    text-align: left;
    padding: 8px;
  }
  & tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.login-wrapper {
  display: flex;
  justify-content: center;
  margin: 5% auto;
}

.login-card {
  padding: 16% 9%;
  background-color: #f7f7f7;
  border-radius: 5%;
  box-shadow: 3px 3px 10px #d7d7d7;
}

.loading-view {
  height: 100vh;
  background-color: var(--body-color);
}
